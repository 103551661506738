<template>
  <div>
    <div class="left-list" v-for="lang in langs" :key="`Lang${lang.iso}`">
      <input
        v-if="$i18n.locale == lang.iso"
        :id="'radio-lang' + lang.iso"
        @input="clickLang(lang.iso)"
        name="radio"
        type="radio"
        checked="checked"
      />
      <input
        v-if="$i18n.locale != lang.iso"
        :id="'radio-lang' + lang.iso"
        @input="clickLang(lang.iso)"
        name="radio"
        type="radio"
      />
      <label :for="'radio-lang' + lang.iso">{{ lang.text }}</label>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "locale-changer",
  data() {
    return {
      langs: [
        {
          iso: "sv",
          text: "Svenska",
        },
        {
          iso: "en",
          text: "English",
        },
        {
          iso: "fi",
          text: "Suomi",
        },
        {
          iso: "no",
          text: "Norsk",
        },
        {
          iso: "dk",
          text: "Dansk",
        },
      ],
    };
  },
  methods: {
    ...mapGetters({
      getLanguage: "language",
    }),
    ...mapActions({
      changeLanguage: "changeLanguage",
    }),
    clickLang: function (lang) {
      //this.$i18n.locale =lang
      this.$i18n.locale = lang;
      this.changeLanguage(lang);
    },
  },
};
</script>
