import api from "@/services/api";

export default {
  getCustomers() {
    const sso = JSON.parse(localStorage.getItem("sso"));
    return api().get("/customers/input/" + sso.username + "/" + sso.company);
  },

  getGreenifiedCustomers() {
    return api().get("/customers/greenified");
  },

  getOffers() {
    const sso = JSON.parse(localStorage.getItem("sso"));
    return api().get("/offers/list/" + sso.username + "/" + sso.company);
  },

  authSSO(auth) {
    return api().post("/auth/sso", auth);
  },

  newOffer(company_id, customer_number) {
    const sso = JSON.parse(localStorage.getItem("sso"));
    return api().post("/offers/new", {
      company_id: company_id,
      customer_number: customer_number,
      user: sso.username,
    });
  },

  copyOffer(order_number) {
    const sso = JSON.parse(localStorage.getItem("sso"));
    return api().get(
      "/offers/copy/" + sso.username + "/" + sso.company + "/" + order_number
    );
  },

  getOffer(order_number) {
    return api().get("/offers/edit/" + order_number);
  },

  updateOffer(order_number, offer) {
    return api().put("/offers/edit/" + order_number, {
      offer: offer,
    });
  },

  updateOfferLine(order_number, line) {
    return api().post("/offers/edit/" + order_number + "/line", {
      line: line,
    });
  },

  deleteOfferLine(order_number, line) {
    return api().post("/offers/delete/" + order_number + "/line", {
      line: line,
    });
  },

  getOfferQuestions() {
    return api().get("/offers/questions");
  },

  getOfferFlexParameters() {
    return api().get("/offers/parameters/flex");
  },

  getOfferSuppliers() {
    return api().get("/offers/suppliers");
  },

  getOfferCategories() {
    return api().get("/offers/categories");
  },
  getOfferPdf(order_number) {
    return api().get("/offers/pdf/" + order_number, {
      responseType: "arraybuffer",
    });
  },
};
