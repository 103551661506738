<template>
  <div>
    <div style="height: 90px">&nbsp;</div>
    <new-order v-if="showNewOrder" @closed="showNewOrder = false"></new-order>
    <import-order
      @closed="showImportOrder = false"
      v-if="showImportOrder"
    ></import-order>
    <div class="header rental">
      <div class="container">
        <div class="row">
          <div
            class="col-4 pt-3"
            style="padding-top: 19px; padding-bottom: 19px"
          >
            <img
              height="30"
              style="margin-top: 15px"
              src="https://rental-api.greenified.se/api/logo"
            />
          </div>
          <div class="col-8 pt-3">
            <nav class="navbar navbar-expand-lg">
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item pe-5">
                  <a href="#" class="nav-link" @click="newOrder">
                    {{ $t("newOffer") }}</a
                  >
                </li>
                <li class="nav-item pe-5">
                  <a href="#" class="nav-link" @click="importOrder">
                    {{ $t("importOffer") }}
                  </a>
                </li>
                <li class="nav-item pe-5">
                  <router-link class="nav-link" :to="{ name: 'offers' }">
                    {{ $t("manageOffer") }}</router-link
                  >
                </li>
                <li v-b-toggle.sidebar-2 class="nav-item pe-5">
                  <a class="nav-link"> {{ $t("language") }}</a>
                </li>
                <li class="nav-item pe-0">
                  <a class="nav-link" href="https://greenified.se">
                    {{ $t("logOut") }}</a
                  >
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <b-sidebar
      id="sidebar-2"
      style="z-index: 12"
      :title="$t('language')"
      shadow
      backdrop
    >
      <div class="px-3 py-2">
        <language-switch></language-switch>
      </div>
    </b-sidebar>

    <div style="min-height: 100vh !important" class="rental-content">
      <slot />
    </div>
  </div>
</template>

<style lang="scss"></style>
<script>
import NewOrder from "@/components/modal/NewOrder";
import ImportOrder from "@/components/modal/ImportOrder";
import LanguageSwitch from "@/components/LanguageSwitch";

export default {
  name: "MainLayout",
  components: {
    ImportOrder,
    NewOrder,
    LanguageSwitch,
  },
  data() {
    return {
      showImportOrder: false,
      showNewOrder: false,
      menuOpen: false,
    };
  },
  methods: {
    importOrder() {
      this.showImportOrder = true;
    },
    newOrder() {
      this.showNewOrder = true;
    },
  },
};
</script>
