<template>
  <modal name="import-order" height="auto" @closed="closeSettings">
    <div class="modal-content-header">
      <h3>{{ $t("importOffer") }}</h3>
    </div>
    <div class="pt-1 text-center" v-if="isCreatingOrder">
      <i
        class="fas fa-circle-notch fa-spin mb-5 mt-5 d-inline-block"
        style="font-size: 28px"
      ></i>
    </div>
    <div class="modal-content-body" v-if="!isCreatingOrder">
      <p>{{ $t("importOfferFromRental") }}.</p>

      <div class="row mt-4 mb-3">
        <div class="col">
          <div class="form-group">
            <input
              :placeholder="$t('offerNumber')"
              type="text"
              class="form-control"
              v-model="orderNumberToImport"
            />
          </div>
        </div>
        <div class="col-auto text-end">
          <button class="btn btn-dark" @click="doImport">
            {{ $t("import") }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import RentalAdministration from "@/services/api/rentalAdministration";
export default {
  name: "ImportOrder",
  data() {
    return {
      isCreatingOrder: false,
      orderNumberToImport: "",
    };
  },
  methods: {
    closeSettings() {
      this.$emit("closed");
    },
    doImport() {
      this.isCreatingOrder = true;
      RentalAdministration.copyOffer(this.orderNumberToImport)
        .then((response) => {
          this.$router.push({
            name: "offers.edit",
            params: { id: response.data },
          });
          this.$emit("closed");
          this.isCreatingOrder = false;
        })
        .catch(() => {
          alert(this.$t("couldNotImportOffer"));
          this.isCreatingOrder = false;
        });
    },
  },
  mounted() {
    this.$modal.show("import-order");
  },
};
</script>
