<template>
  <modal width="900" height="600" name="new-order" @closed="closeSettings">
    <div class="modal-content-header">
      <div class="row">
        <div class="col-8">
          <h3>{{ $t("selectCustomer") }}</h3>
        </div>
        <div class="col-4">
          <div class="form-group position-relative">
            <input
              :placeholder="$t('searchCustomer')"
              type="text"
              class="form-control form-control-sm"
              v-model="searchValue"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isLoading && !isCreatingOrder">
      <table class="table">
        <thead>
          <tr>
            <th width="10%" style="padding-left: 24px">
              {{ $t("customerNumber") }}.
            </th>
            <th width="35%">{{ $t("name") }}</th>
            <th width="45%">{{ $t("address") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="customer in filteredCustomers"
            :key="customer.ftgid + '-' + customer.kundnr"
          >
            <td style="padding-left: 24px">{{ customer.kundnr }}</td>
            <td>{{ customer.namn }}</td>
            <td>
              {{ customer.adress }}, {{ customer.postnr }} {{ customer.ort }}
            </td>
            <td style="padding-right: 24px">
              <button
                @click="newOrder(customer)"
                v-if="customer.overforbar === 'J'"
                class="btn btn-black btn-sm"
                style="background-color: #fff"
              >
                {{ $t("select") }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="edit-offer-container pt-1 text-center"
      v-if="isLoading || isCreatingOrder"
    >
      <i
        class="fas fa-circle-notch fa-spin mb-5 mt-5 d-inline-block"
        style="font-size: 28px"
      ></i>
    </div>
  </modal>
</template>

<script>
import RentalAdministration from "@/services/api/rentalAdministration";
export default {
  name: "NewOrder",
  data() {
    return {
      searchValue: "",
      isLoading: false,
      isCreatingOrder: false,
      customers: [],
    };
  },

  computed: {
    filteredCustomers: function () {
      const search = this.searchValue.toLowerCase().trim();

      if (!search) {
        return this.customers;
      }

      return this.customers.filter(
        (c) =>
          c.kundnr.toLowerCase().indexOf(search) > -1 ||
          c.namn.toLowerCase().indexOf(search) > -1
      );
    },
  },
  methods: {
    closeSettings() {
      this.$emit("closed");
    },
    newOrder(customer) {
      this.isCreatingOrder = true;
      RentalAdministration.newOffer(customer.ftgid, customer.kundnr)
        .then((response) => {
          this.$router.push({
            name: "offers.edit",
            params: { id: response.data },
          });
          this.$emit("closed");
          this.isCreatingOrder = false;
        })
        .catch(() => {
          alert(this.$t("couldNotCreateOffer"));
          this.isCreatingOrder = false;
        });
    },
    getCustomers() {
      this.isLoading = true;
      RentalAdministration.getCustomers().then((response) => {
        this.customers = response.data;
        this.isLoading = false;
      });
    },
  },
  mounted() {
    this.getCustomers();
    this.$modal.show("new-order");
  },
};
</script>
